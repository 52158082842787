import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_PRICE_LIST_ROUTE } from "./Graphql";
import { usePriceListContext } from "./PriceListContext";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_SHIPPING_PORTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function RoutesForm() {
  const {
    onChangeNames,
    routesIndex,
    RouteList,
    routesErrorMessage,
    handelCloseDialog,
    setSelectedNames,
    pricListData,
    setRoutesErrorMessage,
    setDisabled,
    setRoutesIndex,
    setRoutesList,
    priceListId,
    selectedNames,
    dialog,
  } = usePriceListContext();
  const { control, handleSubmit, setValue, errors, setError, watch } = useForm({
    defaultValues: {
      destinationPortId: "",
      originPortId: "",
      cpmPrice: "",
    },
  });
  const [savePriceListRoutes] = useMutation(
    gql`
      ${SAVE_PRICE_LIST_ROUTE.query}
    `
  );
  const onSubmitRoute = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }
    const newRoute = {
      destination: {
        id: data.destinationPortId,
        name: selectedNames.destinationPortId,
      },
      origin: { id: data.originPortId, name: selectedNames.originPortId },
      cpmPrice: parseFloat(data.cpmPrice),
    };
    const updateRoutes = RouteList ? [...RouteList] : [];

    if (routesIndex.update) {
      const updatedRoutes = {
        ...updateRoutes[routesIndex.index],
        ...newRoute,
      };
      updateRoutes[routesIndex.index] = updatedRoutes;
      savePriceListRoutes({
        variables: {
          input: {
            ...(routesIndex.update && { id: updatedRoutes.id }),
            priceListId: priceListId,
            destinationPortId: newRoute.destination?.id,
            originPortId: newRoute.origin?.id,
            cpmPrice: parseFloat(newRoute.cpmPrice),
          },
        },
      })
        .then((data) => {
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setRoutesErrorMessage(graphQLErrors[0]?.message);
          }

          console.log(graphQLErrors);
        });
    } else {
      savePriceListRoutes({
        variables: {
          input: {
            priceListId: priceListId,
            destinationPortId: newRoute.destination?.id,
            originPortId: newRoute.origin?.id,
            cpmPrice: parseFloat(newRoute.cpmPrice),
          },
        },
      })
        .then((data) => {
          newRoute.id = data.data?.savePriceListRoute?.id;
          if (data.data?.savePriceListRoute?.id) {
            setDisabled((prev) => ({
              ...prev,
              routeId: true,
            }));
          }
          updateRoutes.unshift(newRoute);
          setRoutesIndex({
            index: 0,
            update: false,
            duplicate: false,
          });
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setRoutesErrorMessage(graphQLErrors?.[0]?.message);
          }
        });
    }
  };
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = React.useState({
    destinationPortId: null,
    originPortId: null,
    cpmPrice: null,
  });
  useEffect(() => {
    if (routesIndex.update) {
      const update = RouteList[routesIndex.index];
      setAutocompleteValues({
        originPortId: update?.origin,
        destinationPortId: update?.destination,
      });
      setValue("cpmPrice", update?.cpmPrice);

      setSelectedNames((prev) => ({
        ...prev,
        ...(update?.cpmPrice && { cpmPrice: update?.cpmPrice }),
        ...(update?.origin?.name && { originPortId: update?.origin?.name }),
        ...(update?.destination?.name && {
          destinationPortId: update?.destination?.name,
        }),
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parsePort = (data, select) => {
    const parsed = data?.filter((i) => i.id !== select);
    return parsed;
  };
  const body = (
    <Grid container justifyContent="flex-start" aligns="center" spacing={2}>
      <Grid xs={12} sm={12} aligns="flex-start">
        <CustomAutocomplete
          rules={{ required: t("fieldIsRequired") }}
          control={control}
          errors={errors}
          name={"originPortId"}
          label={t("departurePort")}
          parseData={(data) => parsePort(data, watch("destinationPortId"))}
          query={LIST_SHIPPING_PORTS_DROPDOWN.query}
          variables={{
            input: {
              mode: pricListData?.priceList?.shippingMode?.code,
            },
          }}
          onChangeValue={(e) => {
            onChangeNames(e, "originPortId");
          }}
          defaultValue={autocompleteValues.originPortId}
        />
      </Grid>
      <Grid xs={12} sm={12} aligns="flex-start">
        <CustomAutocomplete
          rules={{ required: t("fieldIsRequired") }}
          control={control}
          errors={errors}
          name={"destinationPortId"}
          label={t("arrivalPort")}
          parseData={(data) => parsePort(data, watch("originPortId"))}
          query={LIST_SHIPPING_PORTS_DROPDOWN.query}
          onChangeValue={(e) => {
            onChangeNames(e, "destinationPortId");
          }}
          variables={{
            input: {
              mode: pricListData?.priceList?.shippingMode?.code,
            },
          }}
          defaultValue={autocompleteValues.destinationPortId}
        />
      </Grid>
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          type={"number"}
          name={"cpmPrice"}
          label={t("cpmPrice")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>

      {routesErrorMessage && (
        <FormHelperText error>{routesErrorMessage}</FormHelperText>
      )}
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialog.dialog}
      onClose={handelCloseDialog}
      title={t("route")}
      content={body}
      actions={
        <>
          <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmitRoute)}>{t("confirm")}</Button>
        </>
      }
    ></CustomDialog>
  );
}
